import React from 'react'
import Template from './template'
import Template2 from './template2'
function Strecler() {
  return (
    <>
    <Template></Template>
    <Template2></Template2>
    </>
  )
}

export default Strecler